window.makeChart = function (min, max, data) {
  Highcharts.setOptions({
    time: {
      timezoneOffset: -10 * 60,
    },
    lang: {
      resetZoom: "Zoom Out",
      resetZoomTitle: "Return to original view",
    },
  });
  var chart = Highcharts.chart("server-graph", {
    chart: {
      type: "line",
      renderTo: "container",
      backgroundColor: "rgba(255,255,255, 0)",
      zoomType: "x",
      pinchType: "x",
      resetZoomButton: {
        position: {
          // align: 'right', // by default
          // verticalAlign: 'top', // by default
          align: "left",
          verticalAlign: "top",
          x: 10,
        },
        theme: {
          fill: "#222",
          style: {
            color: "white",
          },
          r: 0,
          states: {
            hover: {
              fill: "#333",
              style: {
                color: "white",
              },
            },
          },
        },
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "Time (AEST)",
      },
      min: min,
      max: max,
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: "",
      },
      tickPositioner: function () {
        function numLength(num) {
          return Math.floor(Math.log10(num)) + 1;
        }
        var positions = [],
          interval = Math.max(
            Math.ceil((Math.floor((this.dataMax * 100) / 5 / 10) + 1) / 10),
            1
          ),
          flat = Math.floor(interval / Math.pow(10, numLength(interval) - 1));
        interval = Math.pow(10, numLength(interval) - 1) * flat;
        tick = 0;
        if (this.dataMax !== null && 0 !== null) {
          for (tick; tick - interval <= this.dataMax; tick += interval) {
            positions.push(tick);
          }
        }
        return positions;
      },
      gridLineColor: "rgba(100,100,100,50)",
      gridLineDashStyle: "longdash",
    },
    legend: {
      enabled: true,
      align: "center",
      itemStyle: {
        color: "#fff",
      },
      itemHoverStyle: {
        color: "rgb(180,255,255)",
      },
      itemHiddenStyle: {
        color: "#777",
      },
      padding: 20,
      // title: {
      //     text: 'aaaaaaaaaa',
      //     style: {
      //       textAlign: 'center'
      //     }
      // }
    },
    tooltip: {
      // headerFormat: '<b>{series.name}</b><br>',
      // pointFormat: '{point.x:%l:%M %P}: {point.y} Players',
      style: {
        color: "#FFF",
        fontWeight: "bold",
      },
      backgroundColor: "#222",
      borderRadius: 20,
      formatter: function (f) {
        var currentPoint = this,
          stackValues = "",
          pointers = [];

        chart.series.forEach(function (series) {
          series.points.forEach(function (point) {
            if (
              currentPoint.x === point.x &&
              currentPoint.y === point.y &&
              series.visible
            ) {
              stackValues +=
                '<span style="color: ' +
                point.color +
                '">\u25CF</span> ' +
                series.name +
                ": " +
                point.y +
                " online<br/>";
              pointers.push(point);
            } else {
              point.setState("");
              point.series.setState("");
            }
          });
        });

        return (
          "<b>Server" +
          (pointers.length == 1 ? "" : "s") +
          ":</b>" +
          "<br/>" +
          stackValues +
          "<br/>" +
          Highcharts.dateFormat("%e/%m %l:%M %p", currentPoint.x)
        );
      },

      positioner: function (labelWidth, labelHeight, point) {
        var tooltipX, tooltipY;
        if (point.plotX + labelWidth * 1.25 > this.chart.plotWidth) {
          tooltipX = point.plotX + this.chart.plotLeft - labelWidth - 40;
        } else {
          tooltipX = point.plotX + this.chart.plotLeft + 40;
        }
        tooltipY = point.plotY + this.chart.plotTop - 20;
        return {
          x: tooltipX,
          y: tooltipY,
        };
      },
    },
    credits: {
      enabled: false,
    },
    loading: {
      hideDuration: 500,
      showDuration: 1500,
      labelStyle: {
        color: '#fff',
        opacity: '1',
        fontSize: '2em',
      },
      style: {
        backgroundColor: '#111',
        opacity: '1'
      }
    },
    series: data,
    plotOptions: {
      series: {
        findNearestPointBy: "xy",
        marker: {
          enabled: false,
          symbol: "circle",
          lineWidthPlus: 2,
          radius: 2,
        },
      },
    },
  });
  chart.showLoading("Server Data loading...");
  return chart;
};

window.moreInfo = function (server) {
  var info = document.getElementById(server + "-info");
  var text = document.getElementById(server + "-showmore");
  var showMore = "More info";
  if (info.clientHeight) {
    info.style.height = 0;
    text.innerHTML = showMore;
  } else {
    text.innerHTML = "Less info";
    info.style.height = info.scrollHeight + "px";
  }
};

window.updateStatus = function (server, status) {
  var color,
    text,
    update = false; // Update = update player count
  switch (status) {
    case 0:
      color = "#0ee315";
      text = "online";
      update = true;
      break;

    case -2:
      color = "#ed1811";
      text = "offline";
      update = true;
      break;

    case -1:
    case -3:
    default:
      color = "#ed8611";
      text = "unknown";
      break;
  }

  var status = document.getElementById(server + "-status");
  status.style.color = color;
  status.innerHTML = text;

  return update;
};

if (typeof Math.log10 == "undefined") {
  Object.prototype.log10 = function (n) {
    return Math.log(n) / Math.log(10);
  };
}
